#rogervoice-widget-button p, #rogeraccess-button p {
  font-family: Open Sans, Arial, sans-serif;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #838A98;
}
#rogervoice-widget-button.green > .roger-webapp-button, #rogeraccess-button.green button {
  background-image: linear-gradient(to top, #55ea90, #59e471);
  color: #11485B;
}
#rogervoice-widget-button button, #rogeraccess-button button {
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 0 35px 0 25px;
  background: #01DB87;
  border: none;
  border-radius: 30px;
  font-family: Open Sans, Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #FFF;
  text-align: left;
  margin-bottom: 15px;
}
.rogeraccess-watermark{
  font-size: 15px;
  text-align: left;
  margin-bottom: 15px;
}
.logo-cdc{
  width: 120px;
  line-height: 0;
  text-align: right;
  img {
    width: 120px;
  }
}
.hide-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin-left: auto;
    width: 192px;
    img {
      width: 100%;
    }
  }
}
